@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;600;700&display=swap');

$primary: #be2edd;
$secondary: #1abc9c;
$warning: #FDA7DF;

body {
  margin: 0;
  font-family: 'EthOSregular', 'Overpass Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  padding-bottom: 2rem;
}

/* customize bootstrap */
.btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn:focus {
  box-shadow: unset !important;
}

.spinner-border-sm {
  margin-bottom: 3px;
}

.custom-select:focus {
  box-shadow: unset !important;
  border-color: $secondary;
}

.btn-primary {
  @include button-variant($primary, darken($primary, 3%));
}

.btn-outline-primary {
  @include button-outline-variant($primary, #fff, lighten($primary, 3%), $primary);
}

.btn-secondary {
  @include button-variant($secondary, darken($secondary, 3%));
}

.btn-outline-secondary {
  @include button-outline-variant($secondary, #fff, lighten($secondary, 3%), $secondary);
}

.btn-warning {
  @include button-variant($warning, darken($warning, 3%));
}

.btn-outline-warning {
  @include button-outline-variant($warning, #fff, lighten($warning, 3%), $warning);
}


.btn {
  border-radius: 1.5rem;
}

/* other styles */
.flex {
  display: flex;
}

.sidebar-menu-link {
  text-decoration: none;
  color: black;
}

.sidebar-menu-link:hover {
  text-decoration: none;
}



/* page container */
.dapp-container, .page-container {
  padding: 1rem;
  border-radius: 1.5rem;
  width: 600px;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.dapp-col {
  min-height: 500px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* farming */
.farming-component-main-row {
  justify-content: space-between;
  align-items: center;
}

.farming-component-main-col {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.farming-component-paragraph {
  font-size: 14px;
  text-align: left;
}

.fixed-inflation-paragraph {
  font-size: 14px;
  text-align: left;
}

.no-border {
  border: none;
}

.text-small {
  font-size: 12px;
}

.text-underline {
  text-decoration: underline;
}

.deploy-contract-editor {
  padding: 0.5rem;
  border: 1px $secondary solid;
  border-radius: 1.5rem;
}

.setup-component-main-col {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: column;
}

.setup-component-small-p {
  font-size: 12px;
  text-align: right;
}

/* wusd */
.wusd-dapp-content {
  width: 99%;
}

.wusd-pair-select, .token-filter-input {
  border-color: $secondary;
  border-radius: 1rem;
}

.farming-card {
  border-radius: 1rem;
}

.wusd-stats-advanced {
  cursor: pointer;
}

.health-bar {
  border-radius: 8px;
  padding: 3px 5px;
  text-align: right;
}

/* main header style */
.main-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;
}

.main-header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-header-logo h2 {
    margin-left: 1rem;
}

.main-header-connect {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* app component */
.app-container {
    padding-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-sidemenu {
  align-items: flex-start;
}

@media screen and (max-device-width: 768px) {
  .app-container {
    flex-direction: column;
    padding-top: 0;
  }
  .app-row {
    flex-direction: column;
  }
  .app-sidemenu {
    flex-direction: row !important;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .inflation-name-input {
    width: 100% !important;
  }
}

/* page container */
.page-container, .dapp-container {
  padding: 1rem;
  border-radius: 1.5rem;
  width: 600px;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

@media (min-device-width: 375px) {
  .page-container, .dapp-container {
    width: 310px;
  }

  .page-container-row {
    flex-direction: column-reverse;
  }
}
  
@media (min-device-width: 576px) {
  .page-container, .dapp-container {
    width: 520px;
  }

  .page-container-row {
    flex-direction: column-reverse;
  }
}

@media (min-device-width: 768px) {
  .page-container, .dapp-container {
    width: 650px;
  }

  .page-container-row {
    flex-direction: row;
  }
}

@media (min-device-width: 992px) {
  .page-container, .dapp-container {
    width: 750px;
  }

  .page-container-row {
    flex-direction: row;
  }
}

.page-container-row {
  display: flex;
  justify-content: center;
}

.launch-dapp-button {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
  border-color: transparent;
  background-color: rgba(226, 128, 233, 1);
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

/* sidebar */
.sidebar-menu {
    list-style: none;
}

.sidebar-menu li {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
}

.sidebar-menu a {
    color: inherit;
    text-decoration: none;
    margin-left: 0.5rem;
}

.sidebar-menu-link-selected {
    font-weight: bold;
}

@media screen and (max-device-width: 768px) {
  .sidebar-menu {
      display: flex;
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      padding-inline-start: 10px;
  }
  .sidebar-menu::-webkit-scrollbar {
      display: none;
  }
  .sidebar-menu li {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1rem;
      padding-right: 1rem;
  }
}

/* index page */
.index-image {
  height: 250px;
}

.index-container-row {
  flex-direction: column !important;
}

.index-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.index-title {
  letter-spacing: 0.25rem;
}

.index-paragraph {
  text-align: center;
  padding: 0 5rem;
}

@media screen and(max-device-width: 767px) {
  .index-paragraph {
    padding: 0 !important;
  }
}