@font-face {
    font-family: EthOSregular;
    src: url(./assets/special/NotoSans-Regular.otf);
 }
 @font-face {
    font-family: EthOSregular;
    src: url(./assets/special/NotoSans-Bold.otf);
    font-weight: bold;
 }

 /* ALE UPDATE THIS */
 .sidemenu-active {
    background-color: white;
 }

.body {position: relative;}
.ApplicationAll {width: 990px; display: block; text-align: center; margin: 80px auto 80px auto;}
.ApplicationBox {width: 750px; display: inline-block; margin-left: 20px; vertical-align: top;}
.ApplicationMenu {width: 150px; display: inline-block;}
a {cursor: pointer !important;}
a:disabled {cursor:wait !important;}
.MobileMenu {display: none;}
.spinner-border, .spinner-border-sm, .text-secondary  {color: #7474f7 !important;}
.fantasyMenu {font-size: 13px;z-index: 5; position: relative;}
.fantasyMenuLi {position: relative; cursor: pointer;}
.mr-2, .mx-2 {margin-right: 1rem;}
.menuIconMap {vertical-align: middle; display: inline-block; width: 40px; height: 30px;}
.menuIconEth {vertical-align: middle; display: inline-block; width: 30px; height: 30px;}
.ChangeMod {display: inline-block; vertical-align: middle; font-size: 20px; cursor: pointer; text-decoration: none;}
.ChangeMod:hover {text-decoration: none;}
.BtnConnect, .BtnConnectAfter {font-size: 15px; padding: 4px 13px; display: inline-block; vertical-align: middle; margin: auto 5px auto 20px; background-color: transparent; border-radius: 8px; box-shadow: none; text-shadow: none; text-decoration: none; cursor: pointer;}
.BtnConnect:hover, .BtnConnectAfter:hover {text-decoration: none;}
.LogoM {background-image: url(./assets/images/covlogo.png); display: inline-block; height: 42px;width: 30px; vertical-align: middle; background-repeat: no-repeat;}
.Ditone {width: 20px; height: 20px; display: none; position: absolute; left: -10px;top: 18px; background-image: url(./assets/images/indicator.png); background-repeat: no-repeat;}
.fantasyMenuLi:hover .Ditone {display: block !important; z-index: 10;left: -15px;}
.sidebar-menu-link-selected .Ditone {display: block !important; z-index: 10;}
.infoList {font-size: 13px; margin-bottom: 5px;margin-block-start: 0em;margin-block-end: 0em;}
.infoList p {margin: 0px;margin-block-start: 0em;margin-block-end: 0em; display: inline-block; width: 100%;padding: 4px 0;}
.row {margin-left: 0; margin-right: 0;}
.IndexRegular {display: inline-block; text-align: center;}
.IndexRegular .IndexRegularContents {width: 95%; display: inline-block;}
.IndexRegular .IndexRegularBTN {width: 95%; display: inline-block; margin-bottom: 0px;}
.IndexRegular .IndexRegularText {width: 50%; display: inline-block; text-align: left;vertical-align: middle;}
.IndexRegular .IndexRegularText p {width: 95%; font-size: 13px;}
.IndexRegular .IndexRegularText h3 {width: 95%; font-size: 20px;}
.IndexRegular .IndexRegularImg {width: 50%; display: inline-block; text-align: center;vertical-align: middle;}
.IndexRegular .IndexRegularImg img {width: 90%; height: auto; display: inline-block;}
.btn-Fantasy {border: 2px solid #4d4c4a; color: #ffffff;background-color: #4d4c4a;}
.btn-Fantasy:hover {color: #ffffff; border-color: #4d4c4a;}
.Footer {width: 100%; min-width: 100%; display: inline-block; text-align: center; position: fixed; bottom: 0; left: 0;}
.Footer img {display: none;}
.FooterP { width: 80%; margin: 20px auto 0px auto; display: inline-block; text-align: center;}
.FooterP p {padding: 5px 5px 5px 5px; display: inline-block; width: 100%; font-size: 10px;}
.FooterP p a {text-decoration: underline;}
.page-container, .dapp-container, .DappBox {z-index: 5; position: relative;margin-bottom: 100px;}
.card {background-color: transparent;}
.navbar {z-index: 5;}
.CoveExpl {width: 99%; margin: 30px auto; display: inline-block;}
.CovDesc {width: 50%; text-align: left;display: inline-block;vertical-align: middle;}
.CovDesc h3 {font-size: 24px; margin-bottom: 20px; display: inline-block; font-weight: bold; width: 100%;}
.CovDesc p {font-size: 13px; margin-bottom: 10px; display: inline-block;}
.CovLinks {margin-top: 10px; display: inline-block;}
.CovLinks h5 {font-size: 15px; display: inline-block; font-weight: bold; width: 100%; margin-top: 20px;}
.CovLinks a {font-size: 13px; display: inline-block; margin: 5px 5px; text-decoration: underline !important; cursor: pointer; color: #000000;}
.ConnectBTN {display: inline-block;margin: 30px auto;text-align: center;cursor: pointer; background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);color: #ffffff !important;text-shadow: #c0c0c0 1px 1px;text-decoration: none;cursor: auto;box-shadow: none;border: 1px solid transparent;outline: none;opacity: 1;padding: 10px 30px;border-radius: 12px;font-weight: 500;text-decoration: none;display: inline-block;animation: slidebg 10s linear infinite;}
.Web3 {width: 100%; font-size: 20px; text-align: center; font-weight: bold;}
.Web3 a {text-decoration: underline !important; color: #7474f7; font-weight: bold;}
.CovSide {width: 30%; display: inline-block; vertical-align: middle;margin-right: 8%;}
.CovDiversity {width: 90%; display: block; margin-bottom: 20px;}
.CovDiversity img {width: 100%; height: auto;} 
.CovAMMs {width: 100%; display: inline-block; vertical-align: middle; background-color: #5f5b53; border-radius: 8px; padding: 20px 2%;font-weight: bold !important;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;}
.CovAMMs h5 {font-size: 15px; display: inline-block; font-weight: bold; width: 100%; margin-bottom: 20px; color: #ffffff;}
.CovAMMs a {font-size: 13px; display: inline-block; text-decoration: none !important; font-weight: bold !important; cursor: pointer; width: 100%; text-align: left;color: #ffffff !important;}
.disclamer {font-size: 9px; color: #636363; width: 60%; text-align: center;}
.disclamer a {text-decoration: underline !important;}
.IndexRegularText iframe {border: 2px solid #849BBD; border-radius: 8px;}
@keyframes slidebg {to {background-position:20vw;}}
.DescCov {text-align: center; width: 80%; display: inline-block; margin:0 auto 30px auto;}
.DescCov p {font-size: 14px;}
.DescCov h2 {text-align: center; font-size: 30px; font-weight: bold !important;}
.DescCov h5 {text-align: center; font-size: 20px;font-weight: bold !important; margin: 10px auto;}
.CovTITLE {margin: 2px auto; display: inline-block; width: 90%; text-align: center;}
.CovTITLE figure {width: 47%; display: inline-block; height: auto; margin: 0 0 0rem;}
.CovTITLE figure img {width: 100%; display: inline-block; height: auto;}
.LogoWUSD {width: 80%; margin: 10px auto;display: inline-block;}
.LogoWUSD img {width: 100%; display: inline-block; height: auto;}
.xTitle {width: 100%; display: inline-block; text-align: left; font-weight: bold; margin: 0px auto 10px auto; font-size: 17px;}
.xTitle img {margin-right: 10px; width: 35px; height: 35px;}
.HealthContainer {width:80%; display: inline-block; margin: 10px auto 40px auto;}
.StatsIndicator {width: 45%; padding: 4px 0% 4px 5%; height: auto; display: inline-block;}
.StatsBro {display: inline-block; text-align: left; width: 22%; padding-left: 3%; margin-right: 10% !important;vertical-align: top !important;}
.StatsBroO {margin-bottom: 20px;}
.StatsSis {width: 65%;text-align: left; display: inline-block; vertical-align: middle !important;}
.StatsIndicator p, .StatsIndicator a {font-size: 13px;}
.StatsIndicator h6 {font-size: 14px;}
.StatsLink {display: inline-block; width: 90%; margin: 5px auto; text-align: center;}
.StatsLink a {font-size: 11px; margin: 5px 5px; display: inline-block; padding: 2px 5px; border-radius: 5px; cursor: pointer;}
.health-bar {border-radius: 8px; width: 100%;text-align: left;font-weight: bold;padding: 4px 0;font-size: 14px;}
.health-bar aside {border-radius: 8px; text-align: center; padding: 5px 0;}
.health-bar aside span {background-color: transparent; color: #ffffff;}
.DappBox {border-radius: 20px;padding: 50px; text-align: center; display: inline-block; width: 100%; min-height: 360px;}
.DappBoxI {padding: 70px 50px}
.Web3Disclamer {display: inline-block; text-align: center; margin: 20px auto;}
.DappMenu {width: 100%;display: inline-block;text-align: center;margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px;padding-inline-start: 0px;margin: 0px auto 40px auto;}
.DappMenu li {display: inline-block;cursor: pointer;font-weight: bold;}
.DappMenu li span {font-size: 15px; font-weight: bold;display: block;padding: 0.5rem 1rem;}
.DappMenu .nav-selected {border-bottom: 8px #7474f7 solid;}
.DappMenu .nav-selected span {font-weight: bold;}

.SelectRegular {width: 100%; display: inline-block; font-size: 13px; font-weight: bold; padding: 7px 2%;border: 1px solid; border-radius: 8px; background-color: transparent;max-width: 370px;}
.TextRegular {width: 100%; display: inline-block; font-size: 13px; font-weight: bold; padding: 7px 2%;border: 1px solid; border-radius: 8px; background-color: transparent;max-width: 370px;}
.TextAreaRegular {width: 100%; display: inline-block; height: 100px; font-size: 13px; font-weight: bold; padding: 7px 2%;border: 1px solid; border-radius: 8px; background-color: transparent;max-width: 370px;}
.QuestionRegular {width: 100%; display: inline-block; font-size: 14px; margin: 15px auto 0px auto;}
.QuestionRegular input {display: inline-block; vertical-align: middle;}
.QuestionRegular label {display: inline-block; margin: 0 0 0 10px; vertical-align: middle;}
.Resultsregular {display: inline-block; margin: 10px auto; width: 100%;}
.Resultsregular p {font-size: 16px;}
.Resultsregular img {display: inline-block; margin: auto 5px; width: 25px; height: 25px;}
.DisclamerRegular {width: 100%; display: inline-block; margin: 50px auto;}
.DisclamerRegular p {display: inline-block; width: 80%; font-size: 15px;}
.InputTokensRegular {display: inline-block; width: 80%; margin: 15px auto; max-width: 370px;}
.InputTokensRegular p {display: inline-block; width: 80%; font-size: 15px; margin: 10px auto;}
.InputTokenRegular {width: 100%;max-width: 370px;}
.InputTokenRegular section {width: 100%; display: inline-block;}
.InputTokenRegular aside {width: 100%; display: inline-block; margin-top: 3px; text-align: left;}
.InputTokenRegular aside a {display: inline-block; padding: 2px 4px; background-color: #849BBD; color: #ffffff !important; border-radius: 8px; font-size: 10px; font-weight: bold;vertical-align: middle;}
.InputTokenRegular aside p {display: inline-block; font-size: 12px; margin-left: 10px; vertical-align: middle; color: #5f5b53;}
.InputTokenRegular img {width: 22px;height: 22px;margin-right: 5px;}
.ApproveBTN {display: inline-block; padding: 8px 17px; background-color: #bd84fb; color: #ffffff; border-radius: 20px; font-size: 16px; font-weight: bold; cursor: pointer;}
.ApproveBTN:hover {color: #ffffff; text-decoration: none;}
.ApproveBTN:disabled {font-weight: normal;}
.Web3ActionBTN {display: inline-block; padding: 8px 17px; background-color: #7474f7; color: #ffffff; border-radius: 20px; font-size: 13px; font-weight: bold; cursor: pointer;}
.Web3ActionBTN:hover {color: #ffffff; text-decoration: none;}
.Web3ActionBTN:disabled {font-weight: normal !important;}
.Rebalance {width: 100%; display: inline-block; text-align: center;}
.Rebalance aside {width: 45%; padding-right: 5%; display: inline-block; text-align: left;}
.Rebalance aside h6 {font-size: 18px; margin: 10px auto; display: inline-block; width: 100%;}
.Rebalance aside p {font-size: 11.5px; margin: 2px auto; display: inline-block; width: 100%;}
.CreditAction {width: 45%; display: inline-block; text-align: center;}
.grimoireBox {z-index: 20; position: relative; display: inline-block; width: 85%; margin: 5px auto; border-radius: 12px;}
.grimoireAll {width: 100%; display: inline-block; padding: 20px 2%; text-align: center;}
.MintBurn {width: 100%; display: inline-block;}
.PairSelector {width: 80%; margin: 10px auto; display: inline-block; vertical-align: middle;}
.Web3BTNs {display: inline-block; width: 80%; margin: 15px auto; max-width: 370px; text-align: center;}
.Web3BTNs a {margin: 5px 20px;}
.GrimoureContent {width: 92%; margin: 5px auto; display: inline-block;text-align: left;}
.GrimoureContent h1 {font-size: 35px; margin: 20px auto; display: inline-block; width: 100%; text-align: center; font-weight: bold !important;}
.GrimoureContent article {width: 100%; display: inline-block; margin: 20px auto;}
.GrimoureContent header {width: 80%; display: inline-block; margin: 10px auto;}
.GrimoureContent span {width: 20%; display: inline-block; margin: 10px auto;vertical-align: top;}
.GrimoureContent span img {display: inline-block; width: 90px; height: 90px;}
.GrimoureContent h2 {font-size: 18px; font-weight: bold !important;}
.GrimoureContent p {font-size: 13px; font-weight: normal !important;}
.GrimoureContent figure {width: 100%; display: inline-block; margin: 10px auto;}
.GrimoureContent figure img {display: inline-block; width: 100%; height: auto;}
.GrimoureContent header h2 {font-size: 27px; font-weight: bold !important;}
.GrimoureContent header p {font-size: 13px; font-weight: normal !important;}
.ListOfThings {width: 100%; text-align: center; display: inline-block; margin: 20px auto;max-width: 600px;}
.web2ActionBTN {display: inline-block; padding: 4px 13px; background-color: #849BBD; color: #ffffff; border-radius: 20px; font-size: 14px; font-weight: bold; cursor: pointer;}
.web2ActionBTN:hover {color: #ffffff; text-decoration: none;}
.web2ActionBTN:disabled {font-weight: normal !important;}
.backActionBTN {display: inline-block; padding: 4px 13px; border:1px solid #849BBD; color: #849BBD; border-radius: 20px; font-size: 14px; font-weight: normal; cursor: pointer;}
.backActionBTN:hover {color: #849BBD; text-decoration: none;}
.backActionBTN:disabled {font-weight: normal !important;}
.InputForm {width: 100%; display: inline-block; margin: 10px auto; text-align: center;}
.CreateList {width: 95%; width: 600px; display: inline-block; text-align: center; margin: 10px auto;}
.CreateList h6 {font-size: 20px; display: inline-block; width: 100%; text-align: center; margin: 15px auto;}
.InputForm input, .InputForm textarea {margin: 10px auto 0px auto;}
.InputForm p {font-size: 12px; display: block; margin: 5px auto; text-align: left; width: 300px;}
.InputForm input[type="file"] {width: auto; text-align: center; margin: 10px auto;}
.Web2ActionsBTNs {width: 300px; text-align: center; display: inline-block; margin: 20px auto;}
.Web2ActionsBTNs a {margin: auto 10px;}
.LoadInput input {margin-right:5px;}
.TokenLoaded {margin: 20px auto; display: inline-block; text-align: center; width: 100%;}
.TokenLoaded > div {width: 300px; text-align: center; display: inline-block; position: relative;}
.TokenLoaded > div .backActionBTN {display: inline-block; position: absolute; z-index: 10; top: 4px; left: -50px;}
.TokenLoaded img {width: 23px; height: 23px; margin: auto 5px; display: inline-block;}
.TokenLoaded p {font-size: 12px; display: block; margin: 5px auto; text-align: center; width: 300px;}
.CreateList p {font-size: 12px; display: block; margin: 5px auto; text-align: center; width: 300px;}
.TextRegularS {width: 90%; display: inline-block; font-size: 13px; font-weight: bold; padding: 7px 2%;border: 1px solid; border-radius: 8px; background-color: transparent;max-width: 330px;}
.CheckboxQuestions aside {width: 10%; display: inline-block; margin-left: 3px; font-size: 18px; }
.CreateListS .TextRegular {width: 300px;}
.InputForm .SpecialInputPerch input {margin: 0;}
.InputForm .SpecialInputPerch {margin-top: 10px;}
.PathSelected {width: 300px; text-align: center; margin: 5px auto; display: block;}
.PathSelected p {width: auto; display: inline;}
.FarmSetup {text-align: center;}
.MainExploration {width: 95%; display: inline-block; margin: 10px auto; width: 600px;}
.SortSection {width: 100%; text-align: center; display: inline-block; margin: 10px auto; min-width: 600px;} 
.SortSection .SelectRegular {width: 45%; margin: 0 2.5%;}
.SortSection .TextRegular {width: 45%; margin: 0 2.5%;}
.ImgStand {width: 30px; height: 30px; display: inline-block;}
.FarmContract, .FarmSetup, .IndexContract {width: 100%; display: inline-block; margin: 10px auto; min-width: 600px; border: 1px solid; border-radius: 12px; padding: 3%; text-align: left;}
.InflationContract {width: 100%; display: inline-block; margin: 10px auto; min-width: 600px; border: 1px solid; border-radius: 12px; padding: 3%; text-align: left;}
.FarmContractOpen {width: 100%; display: inline-block; margin: 10px auto; min-width: 600px; border-bottom: 1px solid; border-radius: 0px; padding: 3%; text-align: left;}
.InflationContractOpen {width: 100%; display: inline-block; margin: 10px auto; min-width: 600px; border-radius: 0px; padding: 3%; text-align: left;}
.FarmTitle {width: 40%; display: inline-block; margin-right: 1%; text-align: left;}
.FarmTitle figure {display: inline-block; width: 40%; text-align: center;margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px; margin-bottom: 5px; vertical-align: middle;}
.FarmTitle figure img {display: inline-block; width: 55px; max-height: 110px; vertical-align: middle;}
.FarmTitle aside h6 {font-size: 16px; text-align: center; display: inline-block; width: 95%;}
.FarmTitle aside {width: 60%;display: inline-block;text-align: center;margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px; margin-bottom: 5px; vertical-align: middle;}
.FarmThings {width: 55%; margin-left: 4%; display: inline-block; vertical-align: middle;}
.FarmThings p {display: inline-block; width: 95%; font-size: 14px; margin-bottom: 5px;}
.FarmThings p a {text-decoration: underline !important;}
.FarmContractOpen .FarmThings p {font-size: 12px;margin-block-start: 0em;margin-block-end: 0em;}
.FarmSetupMain {width: 100%; display: inline-block;}
.FarmSetupMain h5 {width: 45%; display: inline-block; font-size: 16px; vertical-align: top;}
.FarmSetupMain h5 a {font-size: 11px; margin: 5px 5px; display: inline-block; padding: 2px 5px; border-radius: 5px; cursor: pointer;}
.FarmSetupMain aside {width: 50%; display: inline-block;vertical-align: top; margin-left: 2%;}
.FarmSetupMain aside p {font-size: 12px; width: 100%; text-align: right; margin-bottom: 5px;}
.SetupFarmingInstructions {width: 100%; display: inline-block; margin-top: 10px; text-align: left;}
.SetupFarmingInstructions p {display: inline-block; font-size: 14px; margin-bottom: 2px; width: 42%; margin-left: 2%;}
.SetupFarmingInstructions p span {font-size: 12px;}
.SetupFarmingInstructions figure {display: inline-block; margin-right: 5px; margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px;}
.SetupFarmingInstructions img {height: auto;width: 35px;}
.SetupFarmingOthers {display: inline-block; width: 100%; margin: 10px 0%;vertical-align: middle;}
.SetupFarmingOthers p {font-size: 13px; margin: 5px; display: inline-block;}
.SetupFarmingOthers p span {font-size: 11px;}
.YourFarmingPositions {width: 100%; border-top: 1px solid; padding-top: 10px; text-align: center;}
.YourFarmingPositions p {font-size: 13px; margin: 5px; display: inline-block; width: 100%;}
.YourFarmingPositions .web2ActionBTN, .YourFarmingPositions .backActionBTN {margin: 5px;}
.FarmYou {width: 48%; display: inline-block; margin-right: 2%; vertical-align: top; text-align: left;}
.Farmed {width: 48%; display: inline-block;vertical-align: top; text-align: right;}
.SetupFarmingBotton {display: inline-block; width: 15%; vertical-align: middle; text-align: left;}
.InflationContract h4 {font-size: 20px; display: inline-block; width: 70%; font-weight: bold; vertical-align: middle; margin-bottom: 20px;}
.InflationContractLinks {display: inline-block; width: 28%; text-align: right;vertical-align: middle;}
.InflationContractLinks a {font-size: 11px; margin: 5px 5px; display: inline-block; padding: 2px 5px; border-radius: 5px; cursor: pointer;}
.InflationContract p {font-size: 13px; display: inline-block; vertical-align: middle; width: 60%;}
.InflationContract .InflationContractButton {width: 30%; margin-left: 9%; display: inline-block; vertical-align: middle; text-align: right;}
.InflationContractAll {width: 100%; display: inline-block;}
.InflationContractOpen h3 {font-size: 30px; width: 70%; text-align: left; display: inline-block; font-weight: bold; vertical-align: middle;}
.InflationContractOpenBack {width: 29%; text-align: right; display: inline-block; vertical-align: middle;} 
.TokenOperation {width: 95%; text-align: left; display: inline-block; margin: 10px auto; border: 1px solid; padding: 2%; border-radius: 12px;}
.TokenOperation h6 {font-size: 20px; font-weight: bold; display: inline-block; width: 28%; vertical-align: middle; margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px; margin-bottom: 0;}
.TokenOperation p {font-size: 16px; width: 100%; display: inline-block; margin-top: 20px;}
.TokenOperation p img {width: 25px; margin: auto 5px; display: inline-block;}
.TokenOperationLinks {display: inline-block; width: 71%; vertical-align: middle; text-align: left;}
.TokenOperationLinks a {font-size: 11px; margin: 5px 5px; display: inline-block; padding: 2px 5px; border-radius: 5px; cursor: pointer;}
.TokenOperationExecute {width: 100%; text-align: center; display: inline-block; margin: 30px auto;}
.TokenOperationExecute h5 {font-size: 18px; font-weight: normal; vertical-align: middle; display: inline-block; margin: auto 5px;}
.TokenOperationExecute .SelectRegular {width: 20%;margin: auto 5px; display: inline-block; vertical-align: middle;}
.ExploreCreate {text-align: right;}
.InputForm h5 {font-size: 16px; margin: 30px auto 5px auto;}
.InputForm .imputCool {width: 300px; border: 1px solid #849BBD;}
.CovCODEEDITOR {width: 560px; height: 400px;; display: block; margin: 20px auto;}
.CovCODEEDITOR section {width: 560px !important; height: 400px !important;display: block !important;}
.monaco-editor {height: 400px !important;}
.CheckboxQuestions {width: 100%; display: inline-block; margin: 10px auto;}
.CheckboxQuestions label {width: 100%; display: inline-block; margin: 10px auto; text-align: center;}
.CheckboxQuestions label h5 {display: inline-block;font-size: 16px; margin: auto 10px; vertical-align: middle;}
.CheckboxQuestions label input[type="checkbox"] {display: inline-block;vertical-align: middle;}
.SpecialInputPerch {width: 100%; display: inline-block;max-width: 370px; position: relative;}
.SpecialInputPerch aside {position: absolute; right: 0; width: 40px; font-size: 18px; color: #849BBD; border-left: 1px solid #849BBD; top: 4px;}
.TokenInflLoaded {width: 100%; display: inline-block; text-align: center; margin: 20px auto;}
.TokenInflLoaded h5 {font-size: 18px; display: inline-block; margin: auto 10px auto auto; vertical-align: middle; font-weight: bold;}
.TokenInflLoaded img {width: 40px; display: inline-block; height: auto; vertical-align: middle;}
.IndexLogo {width: 100px; height: 100px; display: inline-block; vertical-align: middle;}
.IndexLogo img {width: 100px; height: 100px;}
.IndexLogoL {width: 150px; height: 150px; display: inline-block; vertical-align: middle;}
.IndexLogoL img {width: 150px; height: 150px;}
.IndexInfo {width: 400px; display: inline-block; margin-left: 50px; vertical-align: middle;}
.IndexInfo h6 {font-size: 18px; width: 100%; display: inline-block;}
.IndexTokens {display: inline-block; width: 100%; margin-top: 10px;}
.IndexTokens p {display: inline-block; margin: 5px; font-size: 12px;}
.IndexTokens img {width: 23px;}
.IndexContractBTN {width: 100%; display: inline-block; text-align: right;}
.IndexContractOpenInfo {width: 95%; display: inline-block; margin: 10px auto 0 auto; padding-bottom: 10px; position: relative;}
.IndexContractOpenInfo .web2ActionBTN {position: absolute; top: -10px; right: 0;}
.IndexThings {width: 450px; display: inline-block; margin-left: 40px; vertical-align: middle; text-align: left;}
.IndexThings p {font-size: 12px; display: inline-block; width: 100%;}
.IndexThings h3 {font-size: 20px;display: inline-block; width: 100%;}
.IndexThings .StatsLink {width: 100%; margin: 5px 0; text-align: left;}
.IndexContractOpenCollateral {width: 95%; display: inline-block; margin: 15px auto;}
.IndexContractOpenCollateral h6 {width: 100%; display: inline-block; text-align: left;}
.IndexContractOpenCollateralALL {width: 100%;display: inline-block; text-align: left;margin: 10px auto;}
.IndexContractOpenCollateralSingle {width: 30%; display: inline-block; vertical-align: middle; margin: 10px; border: 1px solid;border-radius: 12px;}
.IndexContractOpenCollateralSingleTITLE {width: 100%; display: inline-block; margin: 20px 0 5px 0; text-align: center;}
.IndexContractOpenCollateralSingleTITLE img {display: inline-block; margin: auto 5px; vertical-align: middle; width: 30px;}
.IndexContractOpenCollateralSingleTITLE p {display: inline-block;vertical-align: middle; font-size: 16px; margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px;}
.IndexSinglePerchBar {display: inline-block; width: 80%; border: 1px solid #7474f7; height: 33px; margin-top: 17px; border-radius: 12px; text-align: left;}
.IndexSinglePerchBar span {font-size: 11px; font-weight: bold; margin-left: 5px; display: inline-block; vertical-align: middle;}
.IndexSinglePerchBar aside {display: inline-block; height: 28px; vertical-align: middle; margin: 1px 1px; background-color: #7474f7; border-radius: 12px; min-width: 17%; max-width: 99%;}
.IndexContractOpenCollateralSingle .StatsLink {width: 100%; text-align: center;}
.FarmThings .StatsLink {width: 100%; text-align: left;}
.IndexManageMB {width: 600px; margin: 20px auto; display: inline-block; text-align: center;}
.ShowCollateralNeededBal {width: 100%; display: inline-block; margin: 10px auto; border: 1px solid; border-radius: 12px; padding: 5px;}
.ShowCollateralNeededBal p {display: inline-block; width: auto; font-size: 13px;margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px;margin-inline-end: 0px; margin: 2px 5px;}
.ShowCollateralNeededBal h6 {display: inline-block; margin-top: 5px; width: 100%; font-size: 13px;}
.COMINGSOONCOSIGIUDILI {width: 100%; display: inline-block; margin: 30px auto; text-align: center; font-size: 20px;}
.IndexContractOpenStatistic {width: 95%; display: inline-block; margin: 30px auto; border: 1px solid; border-radius: 12px;}
.IndexContractOpenStatistic p {font-size: 14px; display: inline-block; margin: 10px 10px; vertical-align: middle;}
.IndexManageMB .Web3BTNs {width: 100%;}
.success {position: fixed; top: 0; left: 0; z-index: 99999999999; background-color: #7474f7; padding: 25px 0; text-align: center; border-radius: 12px; width: 100%;}
.success p {font-size: 18px; display: inline-block; color: #ffffff; font-weight: normal;}
.success p a {font-size: 18px; display: inline-block; color: #ffffff; font-weight: bold; text-decoration: underline !important;}
.success > a {font-size: 14px; position: absolute; bottom: 5px; left: 5px;z-index: 999999999999; color: #ffffff;}
.ResultsregularS {font-size: 12px;}
.InputForm .InputTokenRegular .input-group-append {margin-top: 10px;}
.InputForm .InputTokensRegular {width: 100%;}
.ReceiversList {width: 370px; display: inline-block; margin: 10px auto;}
.ReceiverSingle {width: 100%; display: inline-block; margin: 5px auto;}
.ReceiverSingle p {width: 80%; display: inline-block;}
.ReceiverSingle .DeleteAddress {width: 15%; margin-left: 4%; display: inline-block; text-align: center;}
.ReceiverSingle .TextRegular {width: 100%; display: inline-block;}
.InputForm .Web2ActionsBTNs {width: 100%;}
.CreateListOp {width: 100%; padding: 10px 10px; border: 1px solid #849BBD; border-radius: 12px;}
.CreateListOp p {font-size: 15px;}
.SortBox {width: 99%; display: block; margin: 5px auto; text-align: center;}
.SortBox .TextRegular {width: 40%; display: inline-block; margin: auto 5px auto auto;}
.SortOptions {width: 55%;display: inline-block; margin: auto auto auto 10px;}
.SortOptions label {display: inline-block; text-align: right; width: 40%;}
.SortOptions label input {display: inline-block; width: auto; vertical-align: middle;}
.SortOptions label p {display: inline-block;vertical-align: middle; margin-block-start: 0em; margin-block-end: 0em; margin-bottom: 0; margin-left: 10px; font-size: 13px;}
.SortOptions .SelectRegular {width: 50%;}
.FarmActions .QuestionRegular {text-align: center !important;}
.FarmActions .InputTokenRegular {display: block;margin: 30px auto 0px auto;}
.OptionalThingsFarmers {width: 95%; margin: 20px auto; display: block; text-align: center;}
.OptionalThingsFarmers input {display: inline-block; font-size: 14px; vertical-align: middle;}
.OptionalThingsFarmers p {display: inline-block; font-size: 14px; vertical-align: middle; margin-block-start: 0em;margin-block-end: 0em;}
.FarmActions .Web3BTNs {display: block;margin: 20px auto 0px auto;}
.LockedFarmPositions {display: block; margin: 30px auto; width: 100%;}
.ListOfThings h3 {font-size: 20px;}
.FarmActions .Web2ActionsBTNs {width: 100%;}
input[type=range] {height: 31px;-webkit-appearance: none;margin: 10px 0;width: 100%;background-color: transparent;width: 80%; margin: 10px auto;}
 input[type=range]:focus {outline: none;}
 input[type=range]::-webkit-slider-runnable-track {width: 100%;height: 15px;cursor: pointer;animate: 0.2s;box-shadow: 0px 0px 0px #50555C;background: #849BBD;border-radius: 10px;border: 0px solid #000000;}
 input[type=range]::-webkit-slider-thumb {box-shadow: 0px 0px 0px #000000;border: 0px solid #000000;height: 25px;width: 40px;border-radius: 12px;background: #7474F7;cursor: pointer;-webkit-appearance: none;margin-top: -5px;}
 input[type=range]:focus::-webkit-slider-runnable-track {background: #849BBD;}
 input[type=range]::-moz-range-track {width: 100%;height: 15px;cursor: pointer;animate: 0.2s;box-shadow: 0px 0px 0px #50555C;background: #849BBD;border-radius: 10px;border: 0px solid #000000;}
 input[type=range]::-moz-range-thumb {box-shadow: 0px 0px 0px #000000;border: 0px solid #000000;height: 25px;width: 40px;border-radius: 12px;background: #7474F7;cursor: pointer;}
 input[type=range]::-ms-track {width: 100%;height: 15px;cursor: pointer;animate: 0.2s;background: transparent;border-color: transparent;color: transparent;}
 input[type=range]::-ms-fill-lower {background: #849BBD;border: 0px solid #000000;border-radius: 20px;box-shadow: 0px 0px 0px #50555C;}
 input[type=range]::-ms-fill-upper {background: #849BBD;border: 0px solid #000000;border-radius: 20px;box-shadow: 0px 0px 0px #50555C;}
 input[type=range]::-ms-thumb {margin-top: 1px;box-shadow: 0px 0px 0px #000000;border: 0px solid #000000;height: 25px;width: 40px;border-radius: 12px;background: #7474F7;cursor: pointer;}
 input[type=range]:focus::-ms-fill-lower {background: #849BBD;}
 input[type=range]:focus::-ms-fill-upper {background: #849BBD;}
 .FarmSetup .Web3ActionBTN, .FarmSetup .ApproveBTN {padding: 4px 14px;}
 .BreefExpl {font-size: 11px;margin-top: 4px;margin-bottom: 5px;}
 .BreefRecap {font-size: 12px;margin-top: 4px;margin-bottom: 5px;}
 .BreefRecapB {font-size: 13px !important;margin: 15px auto; max-width: 500px; display: block;}
 .Web3ActionBTN .spinner-border,  .Web3ActionBTN .spinner-border-sm, .ApproveBTN .spinner-border, .ApproveBTN .spinner-border-sm {color: #ffffff !important;}
 .Tranferpos {display: inline-block; width: 80%; margin: 5px auto; text-align: center;}
 .Tranferpos input {margin: 5px auto;}
 .DiffWallet {width: 390px; display: block; margin: 10px auto; text-align: center;}
 .BetaAllert {width: 100%;display: block;margin: 20px auto;text-align: center;padding: 10px;color: #ffffff;background-color: #7474f7;border-radius: 12px;}
 .MetamaskAddButton {color: #7474f7; border-radius: 8px; border: 1px solid #7474f7;  padding: 2px 6px;font-size: 11px; margin-left: 5px; text-decoration: none !important; }
 .MetamaskAddButton:hover {border-radius: 8px; border: 1px solid;text-decoration: none !important;}
 .UnlockInfo {font-size: 14px !important;width: 90% !important;display: block !important;}
 .UnlockInfoBal {font-size: 12px !important;width: 90% !important;}
.UnlockDiv, .Tranferpos {padding: 10px 8px; border: 1px solid #849BBD; border-radius: 12px;}
.Web3ActionBTNV {display: inline-block; margin: 10px auto;}
.BalanceImputPRCD {font-size: 13px; margin-left: 5px;}
.BLKEMD {text-decoration: underline !important;}
.hiddenLink {text-decoration: underline; font-size: 12px; color: #50555C; display: block; margin: 20px auto !important;}
.hiddenLink:hover {color: #50555C;}
.TokenSelectedB {padding: 10px; border: 1px solid #7474f7; display: inline-block;width: auto;border-radius: 12px;}
.SetupFarmingInstructionsOMLYG1 .TokenCoolFancy {height: 35px;width: 35px;display: inline-block;vertical-align: middle;}
.SetupFarmingInstructionsOMLYG1 .TokenCoolFancy span {margin-top: 9px;}


.fantasy {background-color: #4d4c4a !important;}
.fantasy .LogoM {background-position: 0 -50px;}
.fantasy .page-container {background-image: url(./assets/images/index.jpg) !important;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;}
.fantasy .dapp-container, .fantasy .DappBox {background-image: url(./assets/images/index.jpg) !important;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;}
.fantasy .TitleCov {color: #ffffff;}
.fantasy .fantasyMenu:hover, .fantasy .fantasyMenu {color: #ffffff;}
.fantasy .BtnConnectAfter, .fantasy .BtnConnect,.fantasy .menuIconEth, .fantasy .ChangeMod {display: none !important;}
.fantasy .IndexRegular {color: #000000;}
.fantasy .Footer img {display: block; position: absolute; bottom: 0; left: 0; min-width: 100%; width: 100%; height: auto; z-index: 1;}
.fantasy .Footer p {color: #252525; display: none;}
.fantasy .Footer p a {font-weight: bold; color: #000000 !important;}
.fantasy .FooterP p {color: #000000;}
.fantasy .FooterP p a, .penguin .disclamer a {font-weight: bold; color: #000000 !important;}
.fantasy .grimoireBox {background-color: #4d4c4a; border: 1px solid #849BBD;}
.fantasy .GrimoureContent {color: #ffffff;}

.penguin h1, .penguin .aside, .penguin input[type="file"], .penguin p, .penguin h2, .penguin h3, .penguin h4, .penguin h5, .penguin h6, .penguin label, .penguin span {color: #ffffff;}
.penguin {background: rgb(58,60,106);background: linear-gradient(135deg, rgba(58,60,106,1) 0%, rgba(0,0,0,1) 41%);}
.penguin .dapp-container, .penguin .DappBox {background-color: #2d2e40 !important;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;}
.penguin .sidebar-menu-link, .penguin .nav-link, .penguin .fantasyMenu:hover, .penguin .fantasyMenu, .penguin .form-check-label, .penguin .infoList, .penguin .StatsBro, .penguin .StatsBroO, .penguin .IndexRegular, .penguin .Web3, .penguin .disclamer, .penguin .TitleCov, .penguin .xTitle, .penguin .StatsIndicator a, .penguin .QuestionRegular label,.penguin .DisclamerRegular p, .penguin .InputTokensRegular p, .penguin .Resultsregular p, .penguin .Resultsregular p, .penguin .Rebalance aside h6, .penguin .Rebalance aside p, .penguin .FarmThings p, .penguin .FarmTitle aside h6, .penguin .FarmSetupMain h5 {color: #ffffff;}
.penguin .custom-control-label::before, .penguin .custom-file-label, .penguin .custom-select, .penguin .custom-select:focus {transition: none; background: url("./assets/images/arrowdown.png") right 0.75rem center/15px 10px no-repeat;border-color: #849BBD !important; box-shadow: none;}
.penguin .token-filter-input {border-color: #849BBD !important;}
.penguin .wusd-pair-select, .penguin .token-filter-input {background-color: #2d2e40 !important; color: #ffffff;}
.penguin .LogoM {background-position: 0 0px;}
.penguin .BtnConnect {border: 1px solid #7474f7; color: #7474f7;}
.penguin .BtnConnectAfter {border: 1px solid #849BBD; color: #849BBD;}
.penguin .FooterP p {color: #adadad;}
.penguin .FooterP p a, .penguin .disclamer a, .penguin .FarmThings p a {font-weight: bold; color: #adadad !important;}
.penguin .card {border: 1px solid #000000; color: #ffffff;}
.penguin .StatsLink a, .penguin .FarmSetupMain h5 a, .penguin .InflationContractLinks a, .penguin .TokenOperationLinks a {border: 1px solid #849BBD; color: #849BBD;text-decoration: none;}
.penguin .StatsLink a:hover, .penguin .FarmSetupMain h5 a:hover, .penguin .InflationContractLinks a:hover, .penguin .TokenOperationLinks a:hover {color: #849BBD; text-decoration: none !important;}
.penguin .nav-item-selected {border-bottom: 8px solid #7474f7;}
.penguin .health-bar {background-color: #1c1c27;}
.penguin .SelectRegular {border-color: #849BBD; color: #849BBD;}
.penguin .TextRegular, .penguin .TextAreaRegular {border-color: #849BBD; color: #849BBD;}
.penguin .input-group-text {background-color: transparent;border-top: 1px solid #849BBD; border-right: 1px solid #849BBD; border-bottom: 1px solid #849BBD; border-left: 0px solid #849BBD; color: #ffffff;}
.penguin .form-control, .penguin .form-control:focus {background-color: transparent;border-top: 1px solid #849BBD; border-left: 1px solid #849BBD; border-bottom: 1px solid #849BBD; border-right: 0px solid #849BBD; color: #ffffff;}
.penguin .FarmContract,.penguin .IndexContract, .penguin .FarmContractOpen, .penguin .FarmSetup, .penguin .InflationContract, .penguin .TokenOperation, .penguin .IndexContractOpenInfo, .penguin .IndexContractOpenCollateralSingle, .penguin .IndexContractOpenStatistic, .penguin .ShowCollateralNeededBal {border-color: #4d4c4a;}
.penguin .YourFarmingPositions {border-color: #636363;}
.penguin .BLKEMD {color: #ffffff !important;}
.penguin .hiddenLink:hover {color: #adadad;}
.penguin .hiddenLink {color: #adadad;}

.magic {background: rgb(210,208,247);background: linear-gradient(135deg, rgba(210,208,247,1) 0%, rgba(253,247,245,1) 41%);}
.magic .dapp-container, .magic .DappBox {background-color: #ffffff !important;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;}
.magic .custom-control-label::before, .magic .custom-file-label, .magic .custom-select, .magic .custom-select:focus {transition: none; background: url("./assets/images/arrowdownblack.png") right 0.75rem center/15px 10px no-repeat;}
.magic .wusd-pair-select, .magic .token-filter-input {background-color: #ffffff !important; color: #000000;}
.magic .LogoM {background-position: 0 -50px;}
.magic .BtnConnect {border: 1px solid #7474f7; color: #7474f7;}
.magic .BtnConnectAfter {border: 1px solid #849BBD; color: #849BBD;}
.magic .TitleCov, .magic .StatsIndicator a {color: #000000;}
.magic .fantasyMenu:hover, .magic .fantasyMenu {color: #000000;}
.magic .form-check-label {color: #000000;}
.magic .IndexRegular {color: #000000;}
.magic .FooterP p {color: #636363;}
.magic .FooterP p a, .magic .disclamer a, .magic .FarmThings p a {font-weight: bold; color: #636363 !important;}
.magic .card {border: 1px solid #aaaaaa; color: #000000;}
.magic .StatsLink a, .magic .FarmSetupMain h5 a, .magic .InflationContractLinks a, .magic .TokenOperationLinks a {border: 1px solid #849BBD; color: #849BBD;}
.magic .StatsLink a:hover, .magic .FarmSetupMain h5 a:hover, .magic .InflationContractLinks a:hover, .magic .TokenOperationLinks a:hover {color: #849BBD;text-decoration: none !important;}
.magic .nav-item-selected {border-bottom: 8px solid #7474f7;}
.magic .health-bar {background-color: #ebebeb;}
.magic .SelectRegular {border-color: #849BBD; color: #849BBD;}
.magic .TextRegular, .magic .TextAreaRegular {border-color: #849BBD; color: #849BBD;}
.magic .input-group-text {background-color: transparent;border-top: 1px solid #849BBD; border-right: 1px solid #849BBD; border-bottom: 1px solid #849BBD; border-left: 0px solid #849BBD; color: #000000;}
.magic .form-control, .magic .form-control:focus {background-color: transparent;border-top: 1px solid #849BBD; border-left: 1px solid #849BBD; border-bottom: 1px solid #849BBD; border-right: 0px solid #849BBD; color: #000000;}
.magic .FarmContract,.magic .IndexContract, .magic .FarmContractOpen, .magic .FarmSetup, .magic .InflationContract, .magic .TokenOperation, .magic .IndexContractOpenInfo, .magic .IndexContractOpenCollateralSingle, .magic .IndexContractOpenStatistic, .magic .ShowCollateralNeededBal {border-color: #ebebeb;}
.magic .IndexSinglePerchBar span {color: #ffffff;}
.magic .YourFarmingPositions {border-color: #bdbdbd;}
.magic .BLKEMD {color: #000000 !important;}
.InputRegularB .form-control, .InputRegularB .form-control:focus {border-right: 1px solid #849BBD;}

.FarmSetup {border: 1px solid #e0e2e6 !important;;border-radius: 15px; background-color: #f7fafe;margin: 15px auto; padding: 3% 3% 1% 3%;}
.penguin .FarmSetup {background-color: #2f3044; border-color: #1c1c27 !important;border-radius: 15px; margin: 15px auto;}
.ActionButtonV1 {text-align: center; width: 60%; margin: 5px auto; display: block;}
.ActionButtonV1 .web2ActionBTN {display: inline-block; text-align: center;}
.ActionButtonV1 .backActionBTN {display: inline-block; text-align: center;}
.FARMV1FancyThings {background-color: #e9effc; border: 1px solid #e0e2e6 !important;; border-radius: 12px; padding: 5px 8px;}
.V1TokenLP {width: 100%; display: inline-block; padding: 10px; border: 1px solid #e0e2e6 !important;;border-radius: 15px; background-color: #e9effc; margin: 10px auto;}
.penguin .V1TokenLP {background-color: rgb(33, 36, 41); border-color: #1c1c27 !important;}
.V1TokenLP .LockedFarmTokensPosition {text-align: left; font-size: 12px; display: inline-block; width: 45%; margin-left: 1%; margin-right: 1%;vertical-align: middle;}
.V1TokenLP .LockedFarmTokensPosition p {font-size: 12px;}
.QuestionRegularFarmTokenRem {display: inline-block; width: 50%;text-align: right;margin-left: 1%;vertical-align: middle;}
.QuestionRegularFarmTokenRem .PrestoSelector {border: none;}
.PositionInfoFarmingBigMoney {width: 100%; display: inline-block; padding: 20px; border: 1px solid #e0e2e6 !important;;border-radius: 15px; background-color: #e9effc; margin: 10px auto;}
.penguin .PositionInfoFarmingBigMoney  {background-color: rgb(33, 36, 41); border-color: #1c1c27 !important;}
.FarmYouFARMFARMFARM p {font-size: 12px;}
.FarmYouFARMFARMFARM a {margin: 5px;}
.web2ActionBTNGigi {background-color: #aebdd2; border-color: #aebdd2;}
.YourFarmingPositionsFarmingFarmingFarmingChiFarmaViveComeUnPAsha .Farmed p {width: 90%;}
.Farmed p {font-size: 12px;}
.YourFarmingPositionsFarmingFarmingFarmingChiFarmaViveComeUnPAsha {width: 100%; display: inline-block; padding: 10px; border: 1px solid #e0e2e6 !important;;border-radius: 15px; background-color: #e9effc; margin: 10px auto;}
.penguin .YourFarmingPositionsFarmingFarmingFarmingChiFarmaViveComeUnPAsha {background-color: rgb(33, 36, 41); border-color: #1c1c27 !important;}
.YouEanBigMoney p {font-size: 11px;}
.TokenCoolFancy {border-radius: 100%; background-color: #849BBD; overflow: hidden; text-align: center;}
.TokenCoolFancy span {font-size: 11px; color: #ffffff !important; text-decoration: none !important; vertical-align: middle; display: inline-block;}
.TokenCoolFancy span:hover {font-size: 11px; color: #ffffff !important; text-decoration: none !important;}
.TokenCoolFancy:hover span {font-size: 11px; color: #ffffff !important; text-decoration: none !important;}
.FarmTitle figure .TokenCoolFancy {display: inline-block; width: 55px; height: 55px; vertical-align: middle;}
.FarmTitle figure .TokenCoolFancy span {margin-top: 17px;font-size: 14px;}
.TokenFarmV3InfoBox .TokenCoolFancy, .TokenOperation .TokenCoolFancy, .uuuuTokenLoad .TokenCoolFancy {width: 25px !important; height: 25px !important; display: inline-block; vertical-align: middle;}
.TokenFarmV3InfoBox .TokenCoolFancy span, .TokenOperation .TokenCoolFancy span, .uuuuTokenLoad .TokenCoolFancy span {font-size: 7px;margin-top: 4px;}
.input-group-append .TokenCoolFancy {width: 22px !important; height: 22px !important; vertical-align: middle; margin-right: 5px;}
.input-group-append .TokenCoolFancy span {font-size: 7px !important;padding: 0% 0;margin-top: -4px !important;}
.IndexTokens .TokenCoolFancy {width: 23px !important; height: 23px !important; vertical-align: middle;display: inline-block;}
.IndexTokens .TokenCoolFancy span {font-size: 7px !important;padding: 0% 0;margin-top: 4px !important;}
.IndexContractOpenCollateralSingleTITLE .TokenCoolFancy {display: inline-block;margin: auto 5px;vertical-align: middle;width: 30px !important; height: 30px !important;}
.IndexContractOpenCollateralSingleTITLE .TokenCoolFancy span {font-size: 9px;margin-top: 3px;}

.PrestoSelector {padding: 5px; margin: 2px 3px; border-radius: 8px; border: 1px solid #849BBD;}
.PrestoSelector span {font-size: 14px; margin: auto 5px auto auto; display: inline-block; vertical-align: middle;}
.FromETHPrestoDesc {width: 100%;}
.FromETHPrestoDesc p {width: 100%;font-size: 12px;}
.FromETHPrestoDesc img {width: 15px; height: 15px;}
.InputTokenRegularS {position: relative;}
.InputTokenRegularS .PrestoSelector {padding: 2px 5px; position: absolute; left: -80px; top: 2px;}
.OnlyMobileVersion {display: none !important;}
.HIDEO {display: none !important;}
.SetupListITEM {padding: 20px; display: block; margin: 10px auto; border: 1px solid #7474F7; border-radius: 12px;}
.SetupListITEM p {font-size: 14px;vertical-align: middle;margin-block-start: 0em;margin-block-end: 0em;margin-inline-start: 0px; margin-inline-end: 0px;margin-bottom: 0rem; text-align: left;}
.SetupListITEMINFO {width: 62%; display: inline-block; margin-right: 8%; vertical-align: middle;}
.SetupListITEMACTIONS {width: 30%;display: inline-block;vertical-align: middle;}
.SetupListITEMACTIONS .web2ActionBTN {display: block; width: 100%; text-align: center; font-size: 12px; padding: 4px 6px; margin: 5px auto;}
.youDIDit {width: 80%; display: block; margin: 5px auto;}
.youDIDit p {font-size: 13px; text-align: left;}
.youDIDit .SuccessText {font-size: 20px; text-align: center; margin: 10px auto;}
.youDIDit .SuccessTextNow {font-size: 25px; text-align: center; margin: 10px auto; color: #7474F7; font-weight: bold;}
.youDIDit .SuccessTextLink {font-size: 11px; padding: 5px 8px; border: 1px solid #849BBD; color: #ffffff !important; display: inline-block; background-color: #849BBD; border-radius: 6px;}
.youDIDit .SuccessTextLink a {color: #ffffff !important; text-decoration: none !important; font-weight: bold;font-size: 11px;}
.Disclamerfinish {font-size: 10px !important; text-align: left; margin: 10px auto; background-color: #7474F7; font-weight: bold; padding: 12px 20px; border-radius: 12px; color: #ffffff !important;}
.specialITEMlink {border-color: #59b0fb !important; border-width: 2px !important; color: #59b0fb !important; font-weight: bold !important;}
.specialERC20link {border-color: #844e4ee6 !important; border-width: 2px !important; color: #844e4ee6 !important; font-weight: bold !important;}
.specialAMMlink {border-color: #849BBD !important; border-width: 2px !important; color: #849BBD !important; font-weight: bold !important;}
.specialV3link {border-color: #bd84fb !important; border-width: 2px !important; color: #bd84fb !important; font-weight: bold !important; border: 2px solid #bd84fb !important;}
.specialMETAlink {border-color: #7472fc !important; border-width: 2px !important; color: #7472fc !important; font-weight: bold !important;}
.uuuuTokenLoad img {width: 30px; max-height: 60px; vertical-align: middle;}
.ITEMsymbolF {font-size: 11px !important; font-weight: normal !important;}
.VersionFarm {color: #bd84fb;font-size: 13px;font-weight: bolder;padding: 2px 4px;border: 2px solid #bd84fb; border-radius: 5px;margin: 0px 0px 0px 10px;}
.VersionFarmOld {color: #849BBD; font-size: 13px;padding: 2.5px 3px; border: 1px solid #849BBD;border-radius: 5px;margin: 0px 0px 0px 10px; font-weight: normal !important;}
.farmInfoCurve {background-color: #e9effc; display: inline-block; width: 56% !important; float: right; height: 100% !important;min-height: 100% !important;max-height: 100% !important;padding: 40px 20px;}
.UniNFTInfo {font-size: 10px; color: #7472fc; font-weight: bold; border: 2px solid #7472fc; padding: 2px 4px; border-radius: 5px; display: inline-block; margin-left: 5px;}
.UniNFTInfo:hover, .UniNFTInfo:active {color: #7472fc; font-weight: bold; border: 2px solid #7472fc;  text-decoration: none !important;}
.MAinTokensel {display: inline-block; margin: 5px auto; width: 100% !important; vertical-align: middle; font-size: 12px ​!important; font-weight: bold;}
.MAinTokensel a {width: 30px; height: 30px; display: inline-block; vertical-align: middle;}
.MAinTokensel a img {width: 30px; height: 30px; display: block;}
.FarmV3APY {display: inline-block; margin: 5px auto; width: 50% !important;}
.penguin .farmInfoCurve {background-color: rgb(33, 36, 41)}
.farmInfoCurveL {width: 45% !important; display: inline-block; vertical-align: middle !important;}
.farmInfoCurveR {width: 45% !important; display: inline-block; margin-left: 5% !important; vertical-align: middle !important;}
.PriceRangeInfoFarm {width: 100% !important; display: block; font-size: 12px !important; text-align: right;}
.InRangeV3 {padding: 2px 4px; font-size: 10px;display: inline-block;}
.UniV3CurveView {display: block; width: 100%; margin-top: 10px; padding: 3%;}
.UniV3CurveViewCurv {width: 95%; height: 30px; margin-bottom: 30px; border-bottom: 1px solid #7472fc; position: relative;}
.CircleLeftV3Curve, .CircleRightV3Curve {position: absolute; bottom: -5px; background-color: #7472fc; border: 1px solid #7472fc; color: #7472fc; height: 8px; width: 8px; border-radius: 100%;}
.CircleLeftV3Curve {left: -7px;}
.CircleRightV3Curve {right: -7px;}
.CircleLeftV3CurvePrice, .CircleRightV3CurvePrice {font-size: 11px;position: absolute;bottom: -22px;}
.CircleLeftV3CurvePrice {left: -7px;}
.CircleRightV3CurvePrice {right: -7px;}
.CircleActualPriceV3 {display: inline-block; text-align: center; position: absolute;left: 50%;bottom: -14px;}
.CircleRightV3Actual {display: inline-block;height: 20px; width: 20px; position: relative;margin-left: -8px;}
.CircleRightV3Actual img {width: 14px;height: 14px;margin-bottom: 20px;}
.CircleRightV3ActualPrice {display: inline-block;font-size: 11px; font-weight: bold;position: absolute; top: -17px;left: 0px;}
.SetupFarmingInstructionsV3 {width: 42%; display: inline-block;vertical-align: middle; padding: 20px;}
.V3PairThings {width: 100% !important;}
.SetupFarmingInstructionsV3 .specialV3link {font-size: 11px !important; font-weight: normal !important; border-radius: 4px !important; padding: 3px 7px; display: inline-block;}
.SetupFarmingInstructionsV3 p {width: auto !important;}
.UniPoolFeeInfo {font-size: 10px; color: #7472fc; font-weight: bold; border: 2px solid #7472fc;padding: 2px 4px; border-radius: 5px; display: inline-block; margin-left: 5px;}
.UniPoolFeeInfo:hover, .UniPoolFeeInfo:active {color: #7472fc; font-weight: bold; border: 2px solid #7472fc;  text-decoration: none !important;}
.BlockInfoV3 {font-size: 11px !important; width: 100%; display: block !important;margin: 2px 0 2px 4px!important;}
.BlockInfoV3B {font-size: 13px !important; width: 100%; display: block !important;margin: 6px 0 4px 4px !important;}
.UniV3TVLFIV {width: 100%; margin: 5px auto; text-align: center; font-size: 10px !important; display: inline-block;}
.UniV3TVLFIV span {font-size: 10px !important;}
.farmInfoCurve p {margin-left: 0 !important;}
.SetupFarmingInstructionsV3 .web2ActionBTN, .SetupFarmingInstructionsV3 .backActionBTN {display: inline-block; margin: 10px auto; width: 90%; text-align: center; border: 1px solid #849BBD;}
.SetupFarmingInstructionsV3 .Web3ActionBTN, .SetupFarmingInstructionsV3 .web2ActionBTNS {display: inline-block;margin: 10px 4px;width: 40%;text-align: center;padding: 3px;font-size: 13px;margin-right: 7px;}
.FarmSetupV3 {width: 100%;border: 1px solid #e0e2e6;border-radius: 15px; background-color: #f7fafe;overflow: hidden;margin: 25px auto;}
.TokenFarmV3InfoBox {width: auto; display: block;margin: 6px 0px 0 4px;}
.TokenFarmV3InfoBox img {width: 25px !important;}
.TokenFarmV3InfoBox span {font-size: 13px; font-weight: bold; color: #5f5b53;}
.TokenFarmV3InfoBox span .Spannino {font-size: 10px;}
.penguin .TokenFarmV3InfoBox span {color: #c1c1c1;}
.FarmSetupV3 .SetupFarmingInstructions {margin-top: 0 !important;}
.FarmSetupV3 hr {margin-block-start: 0em;margin-block-end: 0em;}
.penguin .FarmSetupV3 {background-color: #2f3044; border-color: #1c1c27 !important;}
.V3FarmStatusNew {padding: 1px 3px; font-size: 10px!important; margin-right: 4px; color: #59b0fb !important; border: 2px solid #59b0fb !important; border-radius: 4px; font-weight: bold;}
.V3FarmStatusNope {padding: 1px 3px; font-size: 10px!important; margin-right: 4px; color: #FC4F4F !important; border: 2px solid #FC4F4F !important;border-radius: 4px;font-weight: bold;}
.V3FarmStatusNopeNow {padding: 1px 3px; font-size: 10px!important; margin-right: 4px; color: #844e4ee6 !important; border: 2px solid #844e4ee6 !important;border-radius: 4px;font-weight: bold;}
.V3FarmStatusYEP {padding: 1px 3px; font-size: 10px!important; margin-right: 4px; color: #34b15ee0 !important; border: 2px solid #34b15ee0 !important;border-radius: 4px;font-weight: bold;}
.FarmSetupV3 .Web3BTNs {margin: 20px auto 20px auto;}
.FarmSetupV3 .Web3BTNs .Web3ActionBTN, .FarmSetupV3 .Web3BTNs .ApproveBTN {display: inline-block;padding: 4px 11px;border-radius: 10px;font-size: 14px;}
.OptionalThingsFarmers {width: 95%;margin: 10px auto 20px auto;display: block;text-align: right;}
.OptionalThingsFarmers p {background-color: #e9effc; padding: 3px 8px; border-radius: 5px; font-size: 12px;}
.DeleteMRD {background-color: #e9effc; padding: 8px 12px; border-radius: 5px; font-size: 12px;width: auto !important;margin: 0 17px;}
.penguin .DeleteMRD {background-color: #1c1c27;}
.DeleteMRD input {position: relative;margin-top: -2px;margin-left: 0; margin-right: 5px;vertical-align: middle;display: inline-block;}
.OptionalThingsFarmers p input {position: relative;margin-top: -2px;margin-left: 0; margin-right: 5px;vertical-align: middle;display: inline-block;}
.penguin .OptionalThingsFarmers p {background-color: #1c1c27;}
.BreefRecap {padding: 8px 0; background-color: #e9effc; border-radius: 8px;}
.penguin .BreefRecap {background-color: #1c1c27}
.Web3ActionBTN:disabled {background-color: #5f5b53;}
.FarmContract {background-color: #f7fafe;}
.penguin .FarmContract {background-color: #2f3044;border-color: #1c1c27}
.input-group-append {background-color: #e9effc;}
.penguin .input-group-append {background-color: #2f3044;}
.generationSelector {width: 45%; display: inline-block; margin: 10px 1%; padding: 30px; background-color: #f7fafe; border: 1px solid #e0e2e6; border-radius: 12px;}
.generationSelectorB {width: 92%;}
.penguin .generationSelector {background-color: #2f3044;border-color: #1c1c27}
.generationSelector h6, .FancyExplanationCreate h6 {font-size: 16px; font-weight: bold;}
.generationSelector p, .FancyExplanationCreate p {font-size: 11px;}
.generationSelector h5, .FancyExplanationCreate h5 {font-size: 14px;}
.PriceRangeInput {border: 1px solid #bd84fb;border-radius: 8px;width: 70%;height: 40px;text-align: center;font-size: 20px;font-weight: bold;}
.tickerchanger {display: inline-block; width: 30%; margin: 5px 5%; background-color: #bd84fb; color: #ffffff; border-radius: 8px; font-weight: bold;}
.tickerchanger:hover {text-decoration: none;color: #ffffff}
.FancyExplanationCreate {width: 91%; display: inline-block; margin: 10px auto; padding: 2%; background-color: #f7fafe; border: 1px solid #e0e2e6; border-radius: 12px;}
.penguin .FancyExplanationCreate {background-color: #2f3044;border-color: #1c1c27}
.GenerationTime {font-size: 12px; width: 90%; display: inline-block; margin: 10px auto;}
.InactiveSignalP {color: #ad0002; border: 2px solid #ad0002;padding: 2px 4px;border-radius: 6px;font-size: 13px;margin-left: 4px;}
.InactiveSignalA {color: #34b15ee0; border: 2px solid #34b15ee0;padding: 2px 4px;border-radius: 6px;font-size: 13px;margin-left: 4px;}
.SetupFarmingInstructionsOMLYG1 {width: 100%; text-align: center; margin: 5px auto;}
.SetupFarmingInstructionsOMLYG1 p {width: auto; text-align: center;}
.TVLGen1R {font-size: 11px !important;}
.FarmSetupV3 .FarmYou {width: 45% !important;} 
.FarmSetupV3 .farmed  {width: 45% !important;}
.FancyExplanationCreateS {width: 35%; text-align: center; display: inline-block; vertical-align: middle;}
.web2ActionBTNFFF {margin: 10px auto;}
.littleDisclamer {font-size: 8px !important; margin-top: -9px;}
.FarmBannerV3 {background-color: #7474f7; color: #ffffff; padding: 30px; border-radius: 15px;}
.FarmBannerV3 p a {color: #ffffff !important;}


@media only screen and (max-device-width: 750px) {
   .IndexRegularText iframe {display: none;}
   .page-container, .dapp-container, .DappBox {width: 270px !important; display: inline-block; margin: 0px auto;}
   .TitleCov {display: none;}
   .CovTITLE figure {width: 90%;}
   .DescCov p {font-size: 11px; font-weight: 600; text-align: left; margin-bottom: 5px;}
   .page-container .FooterP {display: none;}
   .TextRegular {width: 100%;}
   .IndexRegular .IndexRegularImg {width: 90%;}
   .dapp-container, .page-container, .DappBox {padding: 2px !important;}
   .StatsBro {display: none;}
   .StatsSis {width: 95%;}
   .TextAreaRegular {max-width: 100%;}
   .nav-link {font-size: 10px;}
   .HealthContainer {width: 100%;display: inline-block;margin: 10px auto 40px auto;}
   .StatsIndicator {width: 50%;}
   .StatsLink {text-align: left;}
   .ApproveBTN {display: inline-block; margin: 10px auto; font-size: 13px;}
   .InputTokenRegular aside p {width: 75%; margin-left: 5%;}
   .StatsIndicator h6 {font-size: 14px;}
   .StatsIndicator p, .StatsIndicator a {font-size: 12px;}
   .Rebalance aside {width: 100%; padding-right: 0; text-align: center; margin-bottom: 15px;}
   .xTitle {font-size: 18px;}
   .xTitle img {width: 27px;height: 27px;}
   .CovDesc {width: 90%;}
   .CovSide {width: 90%; margin-top: 10px; margin-left: 0;}
   .CovAMMs {width: 90%; margin-left: 0;}
   .Menuone li:hover .Ditone {display: none !important;}
   .Ditone {display: none !important;}
   .sidebar-menu-link-selected .Ditone {display: none !important;}
   .Menuone {display: none;}
   .sidemenu-active {display: inline-block; position: fixed; z-index: 30; right: 10px; top: 10px; background-color: #2d2e40; border-radius: 8px;top: 55px; border: 1px solid #849BBD;}
   .penguin .sidemenu-active {background-color: #2d2e40;}
   .magic .sidemenu-active {background-color: #ffffff; color: #000000;}
   .magic .sidebar-menu-link {color: #000000 !important;}
   .MobileMenu {display: inline-block; font-size: 13px; background-color: #7474f7; padding: 3px 5px; border-radius: 5px; color: #ffffff;text-decoration: none;}
   .MobileMenu:hover, .MobileMenu:after {color: #ffffff; text-decoration: none;}
   .ChangeMod {position: fixed;top: 5px;left: 5px;z-index: 99999999999999999999999119;background-color: #7474f7;padding: 4px 4px;font-size: 10px;border-radius: 50%;}
   .penguin .ChangeMod {background-color: #2d2e40}
   .magic .ChangeMod {background-color: #ffffff;}
   .app-container {z-index: 0;}
   .app-container .row {z-index: 0;}
   .BtnConnect, .BtnConnectAfter {font-size: 10px;}
   .magic .fantasyMenu, .fantasy .fantasyMenu {color: #ffffff;}
   .grimoireBox {max-width: 270px;width: 270px;}
   .GrimoureContent header {width: 100%; display: inline-block; margin: 10px auto;}
   .GrimoureContent span {width: 100%; display: inline-block; margin: 10px auto;vertical-align: top; text-align: center;}
   .GrimoureContent span img {text-align: center;}
   .IndexRegular {position: relative;}
   .IndexRegular .IndexRegularText {width: 80%; margin-top: 20px;}
   .IndexRegular .IndexRegularText h3 {width: 95%;font-size: 18px; margin-top: 20px; display: inline-block;}
   .IndexRegularImg {width: 65% !important;}
   .IndexRegular .IndexRegularText p {font-size: 10px;font-weight: bold;}
   .IndexRegular {display: inline-block !important; min-height: 300px !important; padding: 20px 20px 20px 20px !important;}
   .IndexThings, .IndexManageMB, .IndexContractOpenCollateralSingle {width: 90%;}
   .IndexThings {margin-left: auto; margin: 10px auto;}
   .ListOfThings, .IndexContract, .InflationContract, .InflationContractOpen {max-width: 95%;min-width: 95%;}
   .IndexInfo, .MainExploration {width: 90%;}
   .IndexLogo {margin: 10px auto; display: block;}
   .IndexInfo {width: 95%; display: block; margin: 5px auto;}
   .IndexInfo h6 {font-size: 12px;}
   .IndexContractBTN {text-align: center;}
   .InflationContract h4 {width: 100%; font-size: 15px; margin-bottom: 8px;}
   .InflationContractLinks {width: 100%; text-align: center;}
   .InflationContract p {display: none;}
   .InflationContract .InflationContractButton {width: 100%; text-align: center; margin-left: auto; margin: 10px auto;}
   .InflationContractOpen h3 {font-size: 15px;}
   .dapp-container, .DappBox {min-height: 300px;}
   .TokenOperation h6 {width: 100%; font-size: 15px; text-align: center; margin: 8px auto; margin-bottom: 8px;}
   .TokenOperationLinks, .CreateList {width: 100%;}
   .TokenOperation p {font-size: 13px;}
   .TokenOperation p img {width: 20px;}
   .CreateList p {width: 100%;}
   .InputForm input[type="file"] {width: 90%;}
   .Web2ActionsBTNs {width: 100%;}
   .TokenLoaded > div {width: 100%;}
   .CreateList .TokenLoaded > div .backActionBTN {left: -35px;}
   .CreateList .TokenLoaded {width: 90%;margin-left: 25px;}
   .ApplicationBox {width: 100%;margin-left: 0; margin: 0 auto; display: block;border-radius: 0 !important;}
   .ApplicationAll {width: 100%;margin: 0px auto 0px auto;border-radius: 0 !important;}
   .dapp-container, .DappBox {width: 100% !important;border-radius: 0 !important;}
   .app-container {width: 100% !important;border-radius: 0 !important;}
   .page-container-wrapper, .page-container {width: 100% !important;border-radius: 0 !important;}
   .Footer {display: none;}
   #root {padding-bottom: 0 !important;}
   .ApplicationMenu {position: absolute;}
   .fantasy .navbar {background-color: #2d2e40 !important;}
   .FooterP {width: 100%;}
   .DappMenu li span {font-size: 11px;font-weight: bold;display: block;padding: 0.2rem .5rem;}
   .NUUUMobileVersion {display: none !important;}
   .OnlyMobileVersion {display: inline-block !important; margin: 25px auto;}
   .CovSide {margin-bottom: 20px;}
   .CovLinks h5 {margin-top: 0px;}
   .CovDesc h3 {font-size: 20px;margin-bottom: 5px;}
   .CovDiversity {width: 60%;display: inline-block;}
   .CovAMMs {padding: 5px 2%;}
   .CovAMMs h5 {margin-bottom: 10px;}
   .CoveExpl {margin: 30px auto 5px auto;}
   .MenuMobileIndex .Menuone {display: block; padding: 5px 5%; background-color: #5f5b53;width: 200px;border-radius: 12px;box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px;} 
   .MenuMobileIndex .nav-link {display: block;padding: 0.2rem 0em;text-align: left;}
   .MenuMobileIndex .sidemenu-active {position: static; border: none;}
   .MenuMobileIndex .Menuone h5 {color: #ffffff;}
   .Menuone .NoALL {display: none !important;}
   .MenuMobileIndex .NoALL {display: inline-block !important;}
   .PrestoSelector {margin: 0 0px 3px 3px !important;padding: 0px 4px !important;}
   .InputTokenRegularS .PrestoSelector {left: auto;right: 0px;top: -28px;}
   .PrestoSelector span {font-size: 12px;margin: auto 4px auto auto;}
   .QuestionRegular {font-size: 12px;margin: 10px auto 0px auto;}
   .FarmContract, .FarmContractOpen, .FarmSetup {width: 100%;min-width: 100%;}
   .FarmThings {text-align: center; width: 100%; margin-left: 0;}
   .FarmTitle {width: 100%;margin-bottom: 10px;}
   .SortBox .TextRegular {width: 100%;display: block;}
   .SortOptions {width: 100%;margin-top: 10px;display: block;}
   .FarmContractOpen > .FarmThings {display: none;}
   .SetupFarmingInstructions p {width: 100%;margin-left: 0;text-align: center;}
   .DiffWallet {width: 100%;}
   .SetupFarmingOthers p {width: 100%; text-align: center;}
   .MetamaskAddButton {display: block;}
   .FarmYou {width: 100%; margin-left: 0;}
   .Farmed {width: 100%; text-align: center;}
   .LockedFarmPositions {display: block; margin: 10px auto; padding: 10px 0; width: 100%; border-top: 1px solid;}
   .magic .LockedFarmPositions {border-color: #bdbdbd;}
   .penguin .LockedFarmPositions {border-color: #2d2e40;}
   .YourFarmingPositions .web2ActionBTN, .YourFarmingPositions .backActionBTN, .FarmSetup .Web3ActionBTN, .FarmSetup .ApproveBTN {margin: 5px;padding: 5px 8px;font-size: 12px;}
   .UnlockInfo {font-size: 12px !important;}
   .UnlockInfoBal {font-size: 10px !important;}
   .FarmYou a {width: 100%; display: block; margin: 5px auto;}
   .Web2ActionsBTNs a {margin: 4px 4px;font-size: 12px; padding: 4px 8px;}
   .FarmContractOpen {padding: 0 3%;}
   .FarmContract .StatsLink {display: none;}
   .SetupFarmingInstructionsV3 {width: 100%;}
   .DeleteMRD {margin: 0px 9px;}
   .TokenFarmV3InfoBox {width: 48%;display: inline-block;}
   .farmInfoCurve {width: 100% !important;}
   .FarmBannerV3 p {font-size: 10px;}
}

@media only screen and (min-device-width: 751px) and (max-device-width: 1024px) {
   .Menuone {display: none;}
   .sidemenu-active {display: inline-block;}
   .Menuone li:hover .Ditone {display: none !important;}
   .Ditone {display: none !important;}
   .sidebar-menu-link-selected .Ditone {display: none !important;}
   .MobileMenu {display: inline-block;}
   .MobileMenu {display: inline-block; font-size: 15px; background-color: #7474f7; padding: 3px 5px; border-radius: 5px; color: #ffffff;text-decoration: none;}
   .MobileMenu:hover, .MobileMenu:after {color: #ffffff; text-decoration: none;}
   .sidemenu-active {display: inline-block; position: fixed; z-index: 30; right: 10px; top: 10px; background-color: #2d2e40; border-radius: 8px;top: 55px; border: 1px solid #849BBD;}
   .penguin .sidemenu-active {background-color: #2d2e40;}
   .magic .sidemenu-active {background-color: #ffffff; color: #000000;}
   .magic .sidebar-menu-link {color: #000000 !important;}
   .fantasy .fantasyMenu {color: #ffffff;}
   .grimoireBox {max-width: 650px;width: 650px;}
   .ApplicationBox {width: 99%;margin-left: 0; margin: 0 auto; display: block;}
   .ApplicationAll {width: 100%;margin: 40px auto 20px auto;}
   .dapp-container, .DappBox {width: 100% !important; display: inline-block !important;;}
   .app-container {width: 100% !important; display: inline-block !important;;}
   .page-container-wrapper, .page-container {width: 100% !important;display: inline-block !important;}
   .ApplicationMenu {position: absolute;}
}
.FarmBannerV3 p .BannerA {color: #ffffff !important; font-weight: bold;}